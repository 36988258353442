<div class="borderElectionsTop"></div>
<div class="relative pt-8 pb-28 px-4 sm:pt-20 sm:pb-48 sm:px-16 overflow-hidden bg-gray-800 dark:bg-gray-900 dark">
    <svg viewBox="0 0 960 540" width="100%" height="100%" preserveAspectRatio="xMidYMax slice"
        xmlns="http://www.w3.org/2000/svg" class="absolute inset-0 pointer-events-none">
        <g fill="none" stroke="currentColor" stroke-width="100" class="text-gray-700 opacity-25">
            <circle r="234" cx="196" cy="23"></circle>
            <circle r="234" cx="790" cy="491"></circle>
        </g>
    </svg>
    <div class="z-10 relative flex flex-col items-center">
        <div style="display: flex; justify-content: center; text-align: center;">
            <div class="cajaTitulo">
                <h2 class="text-xl font-semibold">
                    <div style="vertical-align: inherit;">
                        <div style="vertical-align: inherit;">
                            <div class="tituloHeader"
                                style="letter-spacing: -0.025em !important; font-weight: 700 !important; color: #0f172a;">
                                RESULTADOS ELECCIONES ESTUDIANTILES UNAH 2022
                            </div>
                        </div>
                    </div>
                </h2>
            </div>

        </div>



    </div>
</div>
<div class="borderElectionsButton"></div>

<section>
    <div *ngIf="(resultadosFacade.responseCargando$  | async)">
        <app-loading [data]="4"></app-loading>
    </div>

    <div class="dashboard" *ngIf="!(resultadosFacade.responseCargando$ | async)">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6 animate__animated animate__fadeInLeft "
                *ngFor="let item of (resultadosFacade.responseDashboard$ | async).card">
                <div class="card card-stats ">
                    <div class="card-header  card-header-icon" [class]="item.class">
                        <div class="card-icon">
                            <i [class]="item.icon"></i>
                        </div>
                        <p class="card-category">{{item.category}}</p>
                        <h1 class="card-title "> {{item.title}}</h1>
                    </div>
                    <div class="card-footer">
                        <div class="stats">
                            <i [class]="item.footerIcon"></i> {{item.titleFooter}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="borderElectionsTop"></div> -->

        <div class="row mt-2" *ngIf="(resultadosFacade.responseDashboard$ | async)?.chart?.length > 0">

            <div *ngFor="let item of (resultadosFacade.responseDashboard$ | async).chart; let i=index" [ngClass]="[item?.class || 'col-md-6 col-sm-12', animacionChart]">
                <mat-card class="card-chart">
                    <mat-card-header>
                        <mat-card-title>{{item.title}}</mat-card-title>
                        <mat-card-subtitle>{{item.subtitle}}</mat-card-subtitle>
                    </mat-card-header>
                    <div class="card-header chartsuccess">
                        <div class="chart-wrapper">
                            <canvas baseChart [datasets]="item.data" [labels]="item.chartLabels"
                            [colors]="chartColors"  [options]="barChartOptions" [legend]="item.chartLegend"
                                [chartType]="item.chartType || 'bar'" [plugins]="barChartPlugins">
                            </canvas>
                        </div>
                    </div>

                    <mat-card-content>
 
                        <p class="card-category">
                            <span class="text-success">{{item.description}} </span>
                        </p>
                    </mat-card-content>

                    <mat-card-actions class="mat-card-footer">
                        <div class="stats">
                            {{item.footer}}
                        </div>
                    </mat-card-actions>
                </mat-card>

            </div>

        </div>

        <!-- <div class="borderElectionsTop"></div> -->

        <div class="row" style="margin: 0px !important; padding-top: 20px !important;">
            <div class="col-md-12 animate__animated animate__zoomIn" style="margin-bottom: 20px !important;">
                <mat-card>
                    <mat-tab-group mat-align-tabs="start">

                        <ng-container *ngFor="let item of (resultadosFacade.responseDashboard$ | async).tables">

                            <!-- Tabs solo para FEUH y CU -->

                            <mat-tab [label]="item?.tab"
                                *ngIf="item.tab === 'Elecciones Federación de Estudiantes de Honduras' || item.tab === 'Elecciones Consejo Universitario ' ">
                                <div class="title" style="padding-left: 1%;">
                                    <div style="letter-spacing: -0.025em !important; font-weight: 500 !important; color: rgba(100, 116, 139, 1);">
                                        {{item?.tableTitle}}
                                    </div>
                                </div>
                                <table mat-table [dataSource]="item?.data" class="mat-elevation-z8">

                                    <ng-container [matColumnDef]="column" *ngFor="let column of item?.th">
                                        <th mat-header-cell *matHeaderCellDef> {{column}} </th>
                                        <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="columsDisplay(item?.th)"></tr>
                                    <tr mat-row *matRowDef="let row; columns: columsDisplay(item?.th);"></tr>
                                </table>
                            </mat-tab>

                            <!-- Tabs para Carreras -->

                            <mat-tab [label]="item?.tab"  *ngIf="item.tab === 'Carreras' ">
                                <div class="title" style="padding-left: 1%;">
                                    <div style="letter-spacing: -0.025em !important; font-weight: 500 !important; color: rgba(100, 116, 139, 1);">
                                        {{item?.tableTitle}}
                                    </div>
                                </div>

                                <mat-form-field appearance="legacy" class="col-md-6">
                                    <mat-label>Busqueda</mat-label>
                                    <input matInput placeholder="Busqueda" [formControl]="filtrarCarreras" autocomplete="off">
                                </mat-form-field>
                                
                                <table class="mt-2 table  bordeTabla tablep" role="table">
                                    <thead class="theadp" style="color: rgb(110, 110, 110) !important;">

                                        <tr class="text-center trCard">
                                            <th style="background-color: white; border-bottom: 1px solid rgb(230, 230, 230) !important;border-top: 0px !important;"
                                                scope="col " role="columnheader"
                                                *ngFor="let item of item?.th">
                                                {{item}}
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody role="rowgroup " class="tableBodyCard">

                                        <ng-container *ngFor="let data of item?.data | search: this.filtrarCarreras?.value: ['Cargo Elección', 'Movimiento', 'Nombre Completo', 'carrera']">
                                            <tr class="text-center" role="row">
                                                <td role="cell " data-title="" class="text-left"
                                                    *ngFor="let columnas of item?.th">
                                                    {{data[columnas]}}
                                                </td>

                                            </tr>


                                        </ng-container>


                                    </tbody>
                                </table>
                            </mat-tab>

                            <!-- Tabs para Unidad Academica -->
                            <mat-tab label="Unidades Academicas" *ngIf="item.tab === 'Unidad Académica'">

                                <div class="row" style="margin: 0px !important;">
                                    <div style="display: flex; justify-content: center; margin-top: 1cm !important;"
                                        class="col-md-6 text-center"
                                        *ngFor="let unidad of (resultadosFacade.responseUnidadesAcademicas$ | async)">
                                        <mat-card class="cardTable">

                                            <mat-card-header>
                                                <mat-card-title>
                                                    <div
                                                        style="letter-spacing: -0.025em !important; line-height: 2rem !important; font-size: 1.5rem !important; font-weight: 600 !important;">
                                                        {{unidad.abreviatura}}
                                                    </div>

                                                </mat-card-title>

                                            </mat-card-header>

                                            <mat-card-content>

                                                <div class="table-responsive">
                                                    <table class="mt-2 table  bordeTabla tablep" role="table">
                                                        <thead class="theadp" style="color: rgb(110, 110, 110) !important;">
    
                                                            <tr class="text-center trCard">
                                                                <th style="background-color: white; border-bottom: 1px solid rgb(230, 230, 230) !important;border-top: 0px !important;"
                                                                    scope="col " role="columnheader"
                                                                    *ngFor="let item of item?.th">
                                                                    {{item}}
                                                                </th>
                                                            </tr>
                                                        </thead>
    
                                                        <tbody role="rowgroup " class="tableBodyCard">
    
                                                            <ng-container *ngFor="let data of item?.data">
                                                                <tr class="text-center" role="row"
                                                                    *ngIf="data.unidadAcademica === unidad.abreviatura">
                                                                    <td role="cell " data-title="" class="text-center"
                                                                        *ngFor="let columnas of item?.th">
                                                                        {{data[columnas]}}
                                                                    </td>
    
                                                                </tr>
    
    
                                                            </ng-container>
    
    
                                                        </tbody>
                                                    </table>
                                                </div>

                                                
                                            </mat-card-content>

                                        </mat-card>
                                    </div>


                                </div>


                            </mat-tab>


                        </ng-container>




                    </mat-tab-group>
                </mat-card>

            </div>
        </div>
    </div>


</section>