<aside class="sidebar">
   
    <div class="encabezado">
      <div class="div-image">
    
          <img src="../../../assets/images/users/undraw_Shared_workspace_re_3gsu.svg" alt="" class="img rounded-circle">
      </div>
      <div class="text-center" style="line-height: 1.5; font-weight: 500; text-overflow: ellipsis; font-size: 14px; color: #FFFFFF;">
        {{nombre}}
      </div>
     
    </div>
    <div id="leftside-navigation" class="side">

        <mat-accordion class="accordion" *ngFor="let menu of (sideMenuFacade.responseMenus$ | async)">
            <mat-expansion-panel [expanded]="true" class="mat-expansion-panelP2">
                <mat-expansion-panel-header>

                    <mat-panel-title>
                        <span class="textSideHeader ">{{menu.texto}}</span>
                    </mat-panel-title>

                </mat-expansion-panel-header>

                <ng-template matExpansionPanelContent style="padding: 0%;" class="contentAccordion">
                    <mat-nav-list appAccordion class="fs2">
                        <ng-container *ngFor="let menuitem of menu?.hijos">
                            <mat-divider></mat-divider>
                            <mat-list-item [routerLinkActive]="['is-active']" [routerLink]="menuitem.url" style="width: 100% !important" class="list">
                                <i class="{{menuitem.icono}} icon text-center mt-2" mat-list-icon></i>
                                <div mat-line class="textSide">{{menuitem.texto }}</div>

                            </mat-list-item>
                        </ng-container>
                    </mat-nav-list>
                </ng-template>

            </mat-expansion-panel>



        </mat-accordion>

      <!-- <ul class="nano-content">
        <li>
          <a href="index.html"><i class="fa fa-dashboard"></i><span>Dashboard</span></a>
        </li>
        <li class="sub-menu">
          <a ><i class="fa fa-cogs"></i><span>UI Elements</span><i class="arrow fa fa-angle-right pull-right"></i></a>
          <ul>
  
            <li><a href="ui-alerts-notifications.html">Alerts &amp; Notifications</a>
            </li>
            <li><a href="ui-panels.html">Panels</a>
            </li>
            <li><a href="ui-buttons.html">Buttons</a>
            </li>
            <li><a href="ui-slider-progress.html">Sliders &amp; Progress</a>
            </li>
            <li><a href="ui-modals-popups.html">Modals &amp; Popups</a>
            </li>
            <li><a href="ui-icons.html">Icons</a>
            </li>
            <li><a href="ui-grid.html">Grid</a>
            </li>
            <li><a href="ui-tabs-accordions.html">Tabs &amp; Accordions</a>
            </li>
            <li><a href="ui-nestable-list.html">Nestable Lists</a>
            </li>
          </ul>
        </li>
       
        <li class="sub-menu">
          <a href="typography.html"><i class="fa fa-text-height"></i><span>Typography</span></a>
        </li>
      
      </ul> -->
    </div>
  </aside>