import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizatedGuard implements CanActivate {
  constructor(private auth:AuthService, private router:Router){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let estado:boolean = true;

    let menus:any[] = []
    menus = JSON.parse(localStorage.getItem("menus")) || [];
    
    //Si no existen menus no permitir el acceso a la ruta ingresada
    // if(menus.length === 0){
    //   estado = false;
    // }else{
    //   menus.forEach( m => {
    //     for (const i in m.hijos) {
    //       let url:string = m.hijos[i].url;
    //       if(url.slice(0,1) != '/'){
    //         url = `/${url}`;
    //       }

    //       // if(url.slice(1, 2))
    //       if(url === state.url){
    //         estado = true;
    //       }
    //     }
    //   });
    // }

    //Si el estado es igual a falso se le cierra la sesion y se le redirigue al login
    // if(estado === false){
    //   this.auth.CerrarSesion();
    // }

    return estado;
  }
  
}
