import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EMPTY, Observable } from "rxjs";
import { BehaviorSubject } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { DataApi } from "src/app/Model/dataApi";
import { DataApiService } from "src/app/services/data-api.service";
import { ToastrServiceLocal } from "src/app/services/toast/toastr.service";
import { MensajesHttpService } from "src/app/utils/mensajesHttp/mensajes-http.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class DashboardFacadeService {
  private Cargando$ = new BehaviorSubject<boolean>(false);
  public responseCargando$: Observable<boolean> = this.Cargando$.asObservable();

  private Opened$ = new BehaviorSubject<boolean>(false);
  public responseOpened$: Observable<boolean> = this.Opened$.asObservable();

  private Dashboard$ = new BehaviorSubject<any>([]);
  public responseDashboard$: Observable<any> = this.Dashboard$.asObservable();

  public dashboard = {
    card: [
      {
        icon: 'fas fa-shopping-basket',
        category: "Total Compras",
        title: "$34,245",
        footerIcon: 'fas fa-calendar-alt',
        titleFooter: "Ultimos 30 dias",
        class: 'card-header-success'
      },
      {
        icon: 'fas fa-dolly-flatbed',
        category: "Productos Comprados",
        title: "700",
        footerIcon: 'fas fa-calendar-alt',
        titleFooter: "Ultimos 30 dias",
        class: 'card-header-info'
      },
    ],
    chart: [
      {
        data: [
          { data: [65, 59, 80, 81, 56, 55, 40], label: "Series A" },
          { data: [28, 48, 40, 19, 86, 27, 90], label: "Series B" },
          {
            data: [180, 480, 770, 90, 1000, 270, 400],
            label: "Series C",
            yAxisID: "y-axis-1",
          },
        ],
        chartLabels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
        chartType: "line",
        chartLegend: true,
        chartStyleColor: [
          {
            backgroundColor: "rgba(80, 227, 194, 0.5)",
            borderColor: "rgba(80, 227, 194, 1)",
            pointBackgroundColor: "rgb(255, 255, 255)",
            pointBorderColor: "rgb(255, 255, 255)",
            pointHoverBackgroundColor: "rgb(255, 255, 255)",
            pointHoverBorderColor: "rgb(255, 255, 255)",
          },
          {
            backgroundColor: "rgba(33, 166, 238, 0.6)",
            borderColor: "rgba(33, 166, 238, 1)",
            pointBackgroundColor: "rgb(255, 255, 255)",
            pointBorderColor: "rgb(255, 255, 255)",
            pointHoverBackgroundColor: "rgb(255, 255, 255)",
            pointHoverBorderColor: "rgb(255, 255, 255)",
          },
          {
            backgroundColor: "rgb(128, 124, 204, 0.5)",
            borderColor: "rgb(128, 124, 204)",
            pointBackgroundColor: "rgb(255, 255, 255)",
            pointBorderColor: "rgb(255, 255, 255)",
            pointHoverBackgroundColor: "rgb(255, 255, 255)",
            pointHoverBorderColor: "rgb(255, 255, 255)",
          },
        ],
      },
      {
        data: [
          { data: [65, 59, 80, 81, 56, 55, 40], label: "Series A" },
          { data: [28, 48, 40, 19, 86, 27, 90], label: "Series B" },
        ],
        chartLabels: ["2006", "2007", "2008", "2009", "2010", "2011", "2012"],
        chartType: "bar",
        barChartLegend: true,
        chartStyleColor: [
          {
            backgroundColor: "rgba(80, 227, 194, 0.5)",
            borderColor: "rgba(80, 227, 194, 1)",
            pointBackgroundColor: "rgb(255, 255, 255)",
            pointBorderColor: "rgb(255, 255, 255)",
            pointHoverBackgroundColor: "rgb(255, 255, 255)",
            pointHoverBorderColor: "rgb(255, 255, 255)",
          },
          {
            backgroundColor: "rgba(33, 166, 238, 0.6)",
            borderColor: "rgba(33, 166, 238, 1)",
            pointBackgroundColor: "rgb(255, 255, 255)",
            pointBorderColor: "rgb(255, 255, 255)",
            pointHoverBackgroundColor: "rgb(255, 255, 255)",
            pointHoverBorderColor: "rgb(255, 255, 255)",
          },
          {
            backgroundColor: "rgb(128, 124, 204, 0.5)",
            borderColor: "rgb(128, 124, 204)",
            pointBackgroundColor: "rgb(255, 255, 255)",
            pointBorderColor: "rgb(255, 255, 255)",
            pointHoverBackgroundColor: "rgb(255, 255, 255)",
            pointHoverBorderColor: "rgb(255, 255, 255)",
          },
        ],
      },
    ],
  };

  constructor(
    public _toast: ToastrServiceLocal,
    public _dataApi: DataApiService,
    public _mensajesHttp: MensajesHttpService
  ) {}

  mostrarDashboard(params?: any) {
    // this.Dashboard$.next([]);
    // this.Cargando$.next(true);
    // const request$ = this._dataApi.PostDataApi(`${environment.site}/api/dashboard/${environment.processEnv}/v1/dashmodule `, params,  11).pipe(
    //   tap((result: DataApi | any) => {
    //     if(result.hasError === false){
    //       this.Dashboard$.next(result.data.table0[0]);
    //     }
    //     this.Cargando$.next(false);
    //   }),
    //   catchError((error: HttpErrorResponse) => {
    //     this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar el dashboard', '');
    //     this.Cargando$.next(false);
    //     return EMPTY;
    //   })
    // );
    // return request$.subscribe();
  }


  reiniciarDash(){
    this.Dashboard$.next([]);
  }

  renderChart(value){
    this.Opened$.next(value);
  }
}
