<!-- <div class="container-fluid lgCard  mx-auto">
    <div class="card card0 border-0">
        <div class="row">
            <div class="col-lg-6">
                <div class="card1 pb-5">
                    <div class="row">
                        <img src="../../../assets/images/Go504.png" class="logo">
                    </div>
                    <div class="row px-3 justify-content-center mt-4 mb-5 border-line">
                        <img src="https://i.imgur.com/uNGdWHi.png" class="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card2 card border-0 px-4 py-5">
                    <div class="text-center">
                        <h1>Bienvenido</h1>
                    </div>
                    <div class="row px-3 mb-4">
                        <div class="line"></div> <small class="or text-center">Or</small>
                        <div class="line"></div>
                    </div>
                    <form [formGroup]="formLogin" (ngSubmit)="login()">
                        <mat-form-field appearance="outline" style="margin-top: 5%;">
                            <mat-label>Correo</mat-label>
                            <input matInput placeholder="Correo" formControlName="usuario" required autocomplete="off">
                        </mat-form-field>

                        <mat-form-field appearance="outline" style="margin-top: 5%;">
                            <mat-label>Contraseña</mat-label>
                            <input matInput [type]="hide ? 'password' : 'text'" placeholder="Contraseña"
                                formControlName="clave" required autocomplete="off">
                            <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                        </mat-form-field>

                        <div class="text-center mt-4" *ngIf="!(authService.responseCargando$ | async)">
                            <button type="submit" class="buttonPrincipal text-center btnLogin"
                                mat-raised-button>Entrar</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
        <div class="bg-blue py-4 text-center">
            <div class="row px-3"> <small class="ml-4 ml-sm-5 mb-2">Copyright &copy; 2021. GO504 Todos los derechos
                    reservados</small>
                <div class="social-contact ml-4 ml-sm-auto"> <span class="fa fa-facebook mr-4 text-sm"></span> <span
                        class="fa fa-google-plus mr-4 text-sm"></span> <span class="fa fa-linkedin mr-4 text-sm"></span>
                    <span class="fa fa-twitter mr-4 mr-sm-5 text-sm"></span>
                </div>
            </div>
        </div>
    </div>
</div> -->




<div style="display: flex;">
        
    <div class="formDesign" style="width: 50%; height: 100vh; display: flex; align-items: center; justify-content: flex-end;">
        <div id="cover">
            <div id="form-ui">
                <form [formGroup]="formLogin" (ngSubmit)="login()">
                    <div id="form-body">
                        <div class="text-6xl font-bold leading-none text-blue text-center">
                            <div>Bienvenido</div>
                        </div>
                        <mat-form-field appearance="outline" style="margin-top: 35px;">
                            <mat-label>Correo</mat-label>
                            <input matInput placeholder="Correo" formControlName="usuario" required autocomplete="off">
                        </mat-form-field>

                        <mat-form-field appearance="outline" style="margin-top: 35px;">
                            <mat-label>Contraseña</mat-label>
                            <input matInput [type]="hide ? 'password' : 'text'" placeholder="Contraseña"
                                formControlName="password" required autocomplete="off">
                            <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                        </mat-form-field>

                        <div class="text-center mt-4" *ngIf="!(authService.responseLoading$ | async)">
                            <button type="submit" class="buttonPrincipal text-center btnLogin"
                                mat-raised-button>Iniciar Sesión</button>
                        </div>

                        <div class="center" *ngIf="(authService.responseLoading$ | async).loading">
                            <mat-spinner></mat-spinner>
                        </div>

                    </div>
                </form>
                <!-- <div style="display: flex; justify-content: center; align-items: center;" id="img-box">
                    <img src="./assets/images/undraw_All_the_data_re_hh4w.svg" alt="UI Image" class="img-fluid">
                </div> -->
            </div>
        </div>
    </div>
    <div class="design" style="width: 50%;">
        <div
            class="relative hidden mdflex flex-auto items-center h-full justify-center p-16 lgpx-28 overflow-hidden bg-gray-800">
            <svg class="absolute inset-0 pointer-events-none" viewBox="0 0 960 540" width="100%" height="100%"
                preserveAspectRatio="xMidYMax slice" xmlns="http://www.w3.org/2000/svg">
                <g class="text-gray-700 opacity-25" fill="none" stroke="currentColor" stroke-width="100">
                    <circle r="234" cx="196" cy="23"></circle>
                    <circle r="234" cx="790" cy="491"></circle>
                </g>
            </svg>
            <svg class="absolute -top-16 -right-16 text-gray-700" viewBox="0 0 220 192" width="220" height="192"
                fill="none">
                <defs>
                    <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20"
                        patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4" fill="currentColor"></rect>
                    </pattern>
                </defs>
                <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"></rect>
            </svg>
            
            <div class="z-10 relative w-full max-w-2xl">
                <div class="text-7xl font-bold leading-none text-gray-100">
                    <div>Elecciones Estudiantiles UNAH 2022</div>
                </div>
                <!-- <div class="text-5xl font-bold leading-none text-gray-400">
                    <div>Elecciones estudiantiles 2021</div>
                </div> -->
                
                <!-- <div class="mt-6 text-lg tracking-tight leading-6 text-gray-400">
                    Sistema monitoreo de elecciones estudiantiles 2021
                </div> -->
               
            </div>
        </div>
    </div>
</div>