import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { menuInterface } from "src/app/Model/Seguridad/menu.Interface";

@Injectable({
  providedIn: "root",
})
export class NewSidemeduFacadeService {
  //{ "idMenu": 1391, "descripcion": "Modulo de seguridad", "texto": "Seguridad", "idPadre": 0, "icono": "fas fa-shield-alt", "url": "", "urlTemplate": "", "visible": true, "vista": true, "mostrar": true, "ingresar": true, "actualizar": true, "eliminar": true, "activo": true, "subItems": true, "hijos": [{ "idMenu": 1392, "idPadre": 1391, "texto": "Menús", "descripcion": "Mantenimiento menús del sistema", "icono": "fas fa-ellipsis-v", "url": "seguridad/menu", "urlTemplate": "seguridad/menu", "visible": true, "vista": true, "mostrar": true, "ingresar": true, "actualizar": true, "eliminar": true, "activo": true, "subItems": true }, { "idMenu": 1396, "idPadre": 1391, "texto": " Perfiles", "descripcion": "Mantenimiento perfiles del sistema", "icono": "fas fa-id-badge", "url": "seguridad/perfiles", "urlTemplate": "seguridad/perfiles", "visible": true, "vista": false, "mostrar": false, "ingresar": false, "actualizar": false, "eliminar": false, "activo": true, "subItems": true }, { "idMenu": 1395, "idPadre": 1391, "texto": "Usuarios", "descripcion": "Mantenimiento usuarios del sistema.", "icono": "fas fa-users-cog", "url": "seguridad/usuarios", "urlTemplate": "seguridad/usuarios", "visible": true, "vista": false, "mostrar": false, "ingresar": false, "actualizar": false, "eliminar": false, "activo": true, "subItems": true }] }
  public menus: any[] = [
    {
      idMenu: 1397,
      descripcion: "Censo Estudiantil",
      texto: "Censo",
      idPadre: 0,
      icono: "fas fa-vote-yea",
      url: "",
      urlTemplate: "",
      visible: true,
      vista: true,
      mostrar: true,
      ingresar: true,
      actualizar: true,
      eliminar: true,
      activo: true,
      subItems: true,
      hijos: [
        {
          idMenu: 1398,
          idPadre: 1397,
          texto: "Censos",
          descripcion: "Listado censo generados",
          icono: "fas fa-users",
          url: "/censo/listaCenso",
          urlTemplate: "/censo/listaCenso",
          visible: true,
          vista: false,
          mostrar: false,
          ingresar: false,
          actualizar: false,
          eliminar: false,
          activo: true,
          subItems: true,
        },
        {
          idMenu: 1399,
          idPadre: 1397,
          texto: "Cargar Censo",
          descripcion: "Generar Censo",
          icono: "fas fa-file-upload",
          url: "/censo/cargar",
          urlTemplate: "/censo/cargar",
          visible: true,
          vista: false,
          mostrar: false,
          ingresar: false,
          actualizar: false,
          eliminar: false,
          activo: true,
          subItems: true,
        },
        {
          idMenu: 1400,
          idPadre: 1397,
          texto: "Generar Censo",
          descripcion: "Generar Censo",
          icono: "fas fa-vote-yea",
          url: "/censo/generar",
          urlTemplate: "/censo/generar",
          visible: true,
          vista: false,
          mostrar: false,
          ingresar: false,
          actualizar: false,
          eliminar: false,
          activo: true,
          subItems: true,
        },
      ],
    },
    {
      idMenu: 1401,
      descripcion: "Monitoreo",
      texto: "Monitoreo",
      idPadre: 0,
      icono: "fas fa-tachometer-alt",
      url: "",
      urlTemplate: "",
      visible: true,
      vista: true,
      mostrar: true,
      ingresar: true,
      actualizar: true,
      eliminar: true,
      activo: true,
      subItems: true,
      hijos: [
        {
          idMenu: 1402,
          idPadre: 1401,
          texto: "Panel de analisis",
          descripcion: "Panel de analisis",
          icono: "fas fa-chart-pie",
          url: "/dashboard",
          urlTemplate: "/dashboard",
          visible: true,
          vista: false,
          mostrar: false,
          ingresar: false,
          actualizar: false,
          eliminar: false,
          activo: true,
          subItems: true,
        },
      ],
    },
    {
      idMenu: 1403,
      descripcion: "Componente para los soportes ",
      texto: "Soporte",
      idPadre: 0,
      icono: "fas fa-life-ring",
      url: "",
      urlTemplate: "",
      visible: true,
      vista: false,
      mostrar: false,
      ingresar: false,
      actualizar: false,
      eliminar: false,
      activo: true,
      subItems: true,
      hijos: [
        {
          idMenu: 1404,
          idPadre: 1403,
          texto: "Estudiantes",
          descripcion: "Componente para el soporte de los estudiantes",
          icono: "fas fa-users",
          url: "soporte/estudiantes",
          urlTemplate: "soporte/estudiantes",
          visible: true,
          vista: false,
          mostrar: false,
          ingresar: false,
          actualizar: false,
          eliminar: false,
          activo: true,
          subItems: true,
        },
      ],
    },
    {
      idMenu: 1404,
      descripcion: "M",
      texto: "Mantenimiento",
      idPadre: 0,
      icono: "fal fa-tools",
      url: "",
      urlTemplate: "",
      visible: true,
      vista: true,
      mostrar: true,
      ingresar: true,
      actualizar: true,
      eliminar: true,
      activo: true,
      subItems: true,
      hijos: [
        {
          idMenu: 1405,
          idPadre: 1404,
          texto: "Nivel Electivo",
          descripcion: "Nivel Electivo",
          icono: "fas fa-chart-pie",
          url: "/divulgaciones/nivelElectivo",
          urlTemplate: "/divulgaciones/nivelElectivo",
          visible: true,
          vista: false,
          mostrar: false,
          ingresar: false,
          actualizar: false,
          eliminar: false,
          activo: true,
          subItems: true,
        },
        {
          idMenu: 1406,
          idPadre: 1404,
          texto: "Cargo Elección",
          descripcion: "Cargo Elección",
          icono: "fas fa-award",
          url: "/divulgaciones/cargosEleccion",
          urlTemplate: "/divulgaciones/cargosEleccion",
          visible: true,
          vista: false,
          mostrar: false,
          ingresar: false,
          actualizar: false,
          eliminar: false,
          activo: true,
          subItems: true,
        },
        {
          idMenu: 1407,
          idPadre: 1404,
          texto: "Movimientos Estudiantiles",
          descripcion: "Movimientos Estudiantiles",
          icono: "far fa-handshake",
          url: "/divulgaciones/movimientosEstudiantiles",
          urlTemplate: "/divulgaciones/movimientosEstudiantiles",
          visible: true,
          vista: false,
          mostrar: false,
          ingresar: false,
          actualizar: false,
          eliminar: false,
          activo: true,
          subItems: true,
        },
        {
          idMenu: 1407,
          idPadre: 1404,
          texto: "Movimientos - Centro",
          descripcion: "Movimientos - Centro",
          icono: "fas fa-landmark",
          url: "/divulgaciones/movimientosEstudiantilesCentro",
          urlTemplate: "/divulgaciones/movimientosEstudiantilesCentro",
          visible: true,
          vista: false,
          mostrar: false,
          ingresar: false,
          actualizar: false,
          eliminar: false,
          activo: true,
          subItems: true,
        },
        {
          idMenu: 1407,
          idPadre: 1404,
          texto: "Ingreso Votaciones",
          descripcion: "Ingreso Votaciones",
          icono: "fas fa-person-booth",
          url: "/divulgaciones/resultadoPreliminares",
          urlTemplate: "/divulgaciones/resultadoPreliminares",
          visible: true,
          vista: false,
          mostrar: false,
          ingresar: false,
          actualizar: false,
          eliminar: false,
          activo: true,
          subItems: true,
        },
      ],
    }
 
  ];

  private Menus$ = new BehaviorSubject<menuInterface[]>([]);
  public responseMenus$: Observable<menuInterface[]> =
    this.Menus$.asObservable();

  constructor() {}

  consumirMenus() {
    let menus: any[] = [];
    menus = JSON.parse(localStorage.getItem("menus"));
    this.Menus$.next(menus);
  }

  setearMenusFiltrado(item) {
    this.Menus$.next(item);
    localStorage.setItem("menus", JSON.stringify(item));
  }
}
