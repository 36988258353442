import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { CambioPasswordComponent } from './layout/cambio-password/cambio-password.component';
import { FullComponent } from './layout/full.component';
import { MosaicMenuComponent } from './layout/mosaic-menu/mosaic-menu.component';
import { PinSeguridadComponent } from './layout/pin-seguridad/pin-seguridad.component';
import { LoginComponent } from './login/login/login.component';
import { ProfileComponent } from './layout/profile/profile.component';
import { AuthorizatedGuard } from './core/guards/authorizated.guard';
import { ResultadosElectoralesComponent } from './public/resultados-electorales/resultados-electorales.component';

const routerOptions: ExtraOptions = {
  anchorScrolling: "enabled",
  scrollPositionRestoration: 'enabled',
  scrollOffset: [0, 64],
};

const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthorizatedGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'seguridad',
        loadChildren: () => import('./modules/seguridad/seguridad.module').then(m => m.SeguridadModule)
      },
      { path: 'report',
        loadChildren: () => import('./modules/reportes/reportes.module').then(m => m.ReportesModule)
      },
      { path: 'censo',
        loadChildren: () => import('./modules/censo/censo.module').then(m => m.CensoModule)
      },
      { path: 'soporte',
        loadChildren: () => import('./modules/soporte/estudiantes.module').then(m => m.EstudiantesModule)
      },
      { path: 'divulgaciones',
        loadChildren: () => import('./modules/divulgaciones/divulgaciones.module').then(m => m.DivulgacionesModule)
      },
      { path: 'mantenimiento',
        loadChildren: () => import('./modules/mantenimiento/mantenimiento-module.module').then(m => m.MantenimientoModuleModule)
      }

    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'mosaicMenu',
    component: MosaicMenuComponent
  },
  {
    path: 'cambioPassword/:idTipoAccion',
    component: CambioPasswordComponent
  },
  {
    path: 'pinSeguridad/:idTipoAccion',
    component: PinSeguridadComponent
  },
  {
    path: 'perfil',
    component: ProfileComponent
  },
  {
    path: 'resultadosElectorales',
    component: ResultadosElectoralesComponent
  },
  
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  {
    path: '**',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
