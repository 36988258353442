import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Subject } from 'rxjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DataApi } from '../Model/dataApi';
import { loginInterface } from '../Model/login.Interface';
import { MensajesHttpService } from '../utils/mensajesHttp/mensajes-http.service';
import { DataApiService } from './data-api.service';
import { SeguridadService } from './seguridad/seguridad.service';
import { ToastrServiceLocal } from './toast/toastr.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
 //Selectores

 //Respuesta Acción
 private response$ = new Subject<loginInterface[]>();
 public responseAction$: Observable<loginInterface[]> = this.response$.asObservable();

 private loading$ = new BehaviorSubject<boolean>(false);
 public responseLoading$: Observable<boolean> = this.loading$.asObservable();

 private error$ = new BehaviorSubject<any>({ hasError: false, recurso: 'POST', messageError: '', errors: [] });
 public responseError$: Observable<any> = this.error$.asObservable();

 private Cargando$ = new BehaviorSubject<boolean>(false);
 public responseCargando$: Observable<boolean> = this.Cargando$.asObservable();

 private menu: any;



 constructor(private _loginService: SeguridadService, private _toast: ToastrServiceLocal,
   private _mensajeHttp: MensajesHttpService, private _router: Router, public seguridadService: SeguridadService, public _mensajesHttp: MensajesHttpService, public dataApi:DataApiService) {

 }

 login(params: any) {
   this.loading$.next(true);
   this.error$.next({ hasError: false, recurso: 'POST', messageError: '', errors: [] });
   const request$ = this._loginService.PostDataApi('auth/v1/login', params).pipe(
     tap((result: loginInterface | any) => {
       localStorage.setItem("nombre", result?.nombre);
       localStorage.setItem("menus", JSON.stringify(result?.menus));
       localStorage.setItem(environment.tokenLocal, result?.token);
       this.response$.next(result);
       this.loading$.next(false);
      //  this._toast.mensajeSuccess('Te damos la bienvenida al Sistema de Censo Electoral', '');
       this._router.navigateByUrl('/dashboard');

     }),
     catchError((error: HttpErrorResponse) => {
       console.log(error)
       this._mensajeHttp.mostrarErrorHttp(error, 'Ocurrio un error al loguearse', '');
       this.loading$.next(false);
       this.error$.next({ hasError: true, recurso: 'POST', messageError: this._mensajeHttp.mostrarErrorHttpString(error, 'Ocurrio un error al loguearse'), errors: [] });

       return EMPTY;
     })
   );
   return request$.subscribe();
 }

 LoginAWS(params, callback){
  this.Cargando$.next(true);
  this._toast.mensajeLoading('Cargando', '');

  const request$ = this.dataApi.PostAWS(`repositorio/api/files/${environment.processEnv}/v1/auth/login/`, params).pipe(
    tap((result: DataApi | any) => {
      this._toast.clearToasts();
      callback(result?.data[0]?.table0[0])
      this.Cargando$.next(false);
    }),
    catchError((error: HttpErrorResponse) => {
      this._toast.clearToasts();
      this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al entrar al sistema', '');
      this.Cargando$.next(false);
      return EMPTY;
    })
  );
  return request$.subscribe();
}


 CerrarSesion(){
  localStorage.clear();
  this._router.navigateByUrl('login');
 }


}
