import { Component, OnInit } from '@angular/core';
import { ResultadosElectoralesFacadeService } from './resultados-electorales-facade.service';
import * as pluginDataLabels from "chartjs-plugin-datalabels";
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-resultados-electorales',
  templateUrl: './resultados-electorales.component.html',
  styleUrls: ['./resultados-electorales.component.scss']
})
export class ResultadosElectoralesComponent implements OnInit {

  public animacionChart = "animate__animated animate__fadeInLeft";
  public clase = "col-md-12";
  public heightChart;
  public filtrarCarreras = new FormControl('');

  public chartColors: any[] = [
    {
      backgroundColor: ["#ffa822", "#ff6150", "#1ac0c6", "#679186", "#e0f0ea"]
    }
  ];

  public barChartData: {
    labels: ['2006', '2007', '2008', '2009', '2010', '2011', '2012'],
    datasets: [
      { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
      { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
    ]
  };

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: "white",
        anchor: "end",
        align: "center",
      },
    },
    legend: {
      display: true,
      labels: {
        fontColor: "white",
        fontSize: 10
      },
      position: "bottom"
    },
    scales: {
      xAxes: [
        {
          id: "x-axis-0",
          position: "end",
          ticks: {
            fontColor: "rgb(255, 255, 255)",
            fontSize: 12

          },
        },
      ],
      yAxes: [
        {
          id: "y-axis-0",
          position: "left",

          ticks: {
            fontColor: "rgb(255, 255, 255)",
            fontSize: 12
          },
        },
        {
          id: "y-axis-1",
          position: "right",
          // gridLines: {
          //   color: 'white',
          // },
          ticks: {
            fontColor: "rgb(255, 255, 255)",
            fontSize: 12
          },
        },
      ],
    },
    annotation: {
      annotations: [
        {
          type: "line",
          mode: "vertical",
          scaleID: "x-axis-0",
          value: "March",
          borderColor: "white",
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: "white",
            content: "LineAnno",
            fontSize: 12
          },
        },
      ],
    },
  };

  public barChartPlugins = [pluginDataLabels];

  constructor(public resultadosFacade: ResultadosElectoralesFacadeService) {
    localStorage.clear();
    this.resultadosFacade.obtenerUrl('');
    this.resultadosFacade.obtenerUrlUnidades('');
  }

  ngOnInit(): void {
  }

  columsDisplay(item: []) {
    return item.slice();
  }




}
