<mat-toolbar>
    <span class="titleMosaic">Sistema Elecciones Estudiantiles 2021</span>
    <span class="example-spacer"></span>
    <span fxFlex></span>

    <button mat-mini-fab (click)="salir()">
        <mat-icon>exit_to_app</mat-icon>
    </button>
</mat-toolbar>

<div class="pageMosaic" style="margin: 10px;">
    <mat-grid-list #grid [cols]="cols | async" class="myGridList" rowHeight="fit" style="height: 85vh !important" [gutterSize]="'15px'">
        <mat-grid-tile  *ngFor="let tile of (mosaicMenu.responseModules$ | async)"  [rowspan]="1"
              class="configImage"  (click)="navegar(tile)" [ngStyle]="styleObject(tile)">
            <div class="textGrid">
                <span>{{tile.title}}</span>
            </div>
            <div class="overlay">
                <span>
                    {{tile.description}}
                </span>
            </div>

        </mat-grid-tile>

    </mat-grid-list>
</div>