
export const environment = {
  production: false,
  //apiUrl: "http://api-internal.unah.edu.hn/censo/api/",
  apiUrl: "https://6ca8l0m2ug.execute-api.us-east-1.amazonaws.com/dev/api/",
  apiDivulgacion: "https://6ca8l0m2ug.execute-api.us-east-1.amazonaws.com/dev/api/",

  apiUrlSecurity: "https://api-internal.unah.edu.hn/seguridad/api/dev/",
  //apiUrlSecurity: "http://localhost:3006/api/dev/",
  apiUrlSecurityAWS: "http://api-internal.unah.edu.hn/repo/prod/",

  idSistema: "131A7F68-8A0D-49DE-B103-56573CE170A3",
  site: "Elecciones",
  processEnv: 'dev',
  tokenLocal: "token_Elecciones",
  idEmpresa:1,
  dataUsuarioLocal:"data_Elecciones",
  idAfiliado: "-1",
  nombreEmpresa: "504"

};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
