import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DataApi } from 'src/app/Model/dataApi';
import { DataApiService } from 'src/app/services/data-api.service';
import { ToastrServiceLocal } from 'src/app/services/toast/toastr.service';
import { MensajesHttpService } from 'src/app/utils/mensajesHttp/mensajes-http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResultadosElectoralesFacadeService {

  private Cargando$ = new BehaviorSubject<boolean>(false);
  public responseCargando$: Observable<boolean> = this.Cargando$.asObservable();

  private Dashboard$ = new BehaviorSubject<any[]>([]);
  public responseDashboard$: Observable<any[]> = this.Dashboard$.asObservable();

  private UnidadesAcademicas$ = new BehaviorSubject<any[]>([]);
  public responseUnidadesAcademicas$: Observable<any[]> = this.UnidadesAcademicas$.asObservable();

  constructor(public _toast: ToastrServiceLocal, public _dataApi: DataApiService, public _mensajesHttp: MensajesHttpService) { }

  obtenerUrl(params) {
    this.Cargando$.next(true);
    const request$ = this._dataApi.GetDataApi(`api/censo/${environment.processEnv}/v1/repo/unah.data.elecciones/dashboard.json/40/url`, params, 1).pipe(
      tap((result: DataApi | any) => {
        if (!result.hasError) {
          this.obtenerDatosDash(result.data[0].table0[0].url);
        } 
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al obtener los datos de los resultados', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  obtenerDatosDash(url){
    this.Cargando$.next(true);
    const request$ = this._dataApi.GetDataApiDash(url).pipe(
      tap((result: DataApi | any) => {
        if (!result.hasError) {
          console.log((result.data.table0[0]))
          this.Dashboard$.next(result.data.table0[0]);
        } 
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
   
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al obtener los datos de los resultados', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  obtenerUrlUnidades(params) {
    this.Cargando$.next(true);
    const request$ = this._dataApi.GetDataApi(`api/censo/${environment.processEnv}/v1/repo/unah.data.elecciones/unidadesAcademica.json/40/url`, params, 1).pipe(
      tap((result: DataApi | any) => {
        if (!result.hasError) {
          this.obtenerUnidades(result.data[0].table0[0].url);
        } 
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al obtener las unidades academicas', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  obtenerUnidades(url){
    this.Cargando$.next(true);
    const request$ = this._dataApi.GetDataApiDash(url).pipe(
      tap((result: DataApi | any) => {
        if (!result.hasError) {
          this.UnidadesAcademicas$.next(result[0].data);
          console.log(this.UnidadesAcademicas$.value)
        } 
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al obtener los datos de los resultadoslas unidades academicas', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }
}
