import { AfterContentInit, Component, OnInit, ViewChild } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatGridList } from '@angular/material/grid-list';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { SidemeduFacadeService } from '../side-menu/sidemedu-facade.service';
import { MosaicMenuFacadeService } from './mosaic-menu-facade.service';


export interface Tile {
  color: string;
  cols?: number;
  rows: number;
  text: string;
  image?: string;
}

@Component({
  selector: 'app-mosaic-menu',
  templateUrl: './mosaic-menu.component.html',
  styleUrls: ['./mosaic-menu.component.scss']
})
export class MosaicMenuComponent implements OnInit, AfterContentInit{

  @ViewChild('grid', { static: true }) grid: MatGridList;
  cols: Subject<any> = new Subject();
  public breakpoint: number;
  public suscripciones: Subscription = new Subscription();
  
  
  public gridByBreakpoint = {
    xl: 3,
    lg: 3,
    md: 3,
    sm: 2,
    xs: 1
  }


  constructor(private observableMedia: MediaObserver, public mosaicMenu: MosaicMenuFacadeService, public authService:AuthService, public sideMenuFacade:SidemeduFacadeService, public router:Router) {
    this.cols.next(4);
    this.mosaicMenu.mostrarModulo('');
    
  }
  
  ngOnInit() {
    this.cols.subscribe((result) => {
      
    });
  }

  ngAfterContentInit() {
    this.observableMedia.asObservable().subscribe((change: MediaChange[]) => {
      this.cols.next(this.gridByBreakpoint[change[0].mqAlias]);
    });
  }

  salir() {
    this.authService.CerrarSesion();
  }

  navegar(item) {
    // localStorage.setItem("idModulo", item.idModule);
    // let menu = JSON.parse(localStorage.getItem("menusTotales"));
    // menu = menu.filter(m => m.idModulo === item.idModule);
    // this.sideMenuFacade.setearMenusFiltrado(menu);
    this.router.navigateByUrl('/dashboard');
  }
  styleObject(item){

    // let css;
    // let cssStyle = {};

    // if(css != undefined){
    //   css.array.forEach(element => {
    //     Object.assign(cssStyle, element);
    //   });
    // }

    return {'background-image': 'url(' + item.image + ')', 'color': 'white', 'font-weight': 'bold'};
  }
}
