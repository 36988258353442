import { MediaMatcher } from '@angular/cdk/layout';
import {ChangeDetectorRef, Component,OnDestroy,AfterViewInit, Output, EventEmitter} from '@angular/core';
import { environment } from 'src/environments/environment';
import { DashboardFacadeService } from '../modules/dashboard/dashboard/dashboard-facade.service';

/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: ['full.style.css']
})
export class FullComponent implements OnDestroy, AfterViewInit {

  public mobileQuery: MediaQueryList;
  public opened:boolean = true;
  public nombreEmpresa = environment.nombreEmpresa;
  
  private _mobileQueryListener: () => void;

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public media: MediaMatcher,
    public dashboardFacade:DashboardFacadeService
  ) {
    // this.elemento.ontoggle("toggled");
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngAfterViewInit() {
  }

  renderDash(){
    this.opened = (this.opened === false) ? true : false;
    this.dashboardFacade.renderChart(this.opened);
  }
}
