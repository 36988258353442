import { HttpErrorResponse, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService {

  public token: string;
  
  constructor(private router: Router, auth: AuthService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {

    this.token = localStorage.getItem(environment.tokenLocal);

    // if (this.token == null) {
    //   this.token = '';
    // }

    let request = req;
    if(req.body){
      if(req.body.idSistema === undefined){
        req.body.idSistema = environment?.idSistema || '';
      }
    }

    //request = req.clone({ headers: req.headers.set('Access-Control-Allow-Origin','*')})
    //request = req.clone({ headers: req.headers.set('Access-Control-Request-Headers','*')})
    //request = req.clone({ headers: req.headers.set('Content-Type','application/json')})
    //request = req.clone({ headers: req.headers.set('Content-Encoding','gzip')})

    let auth = (req.body?.seguridad === true) ? this.token : 'Bearer ' +  this.token;

    if (this.token) {
      request = req.clone({
        setHeaders: {
          authorization: auth,
        }
      });
    }
 

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          localStorage.clear();
          this.router.navigateByUrl('/login');
        }

        return throwError(err);

      })
    );
  }
}
