import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  
  public dataUsuario: any;


  constructor(public authServices: AuthService, public router:Router) { }

  ngOnInit() {
    this.obtenerDataLocal();
    //Toggle Click Function
    $("#menu-toggle").click(function(e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
      
    });
  }

  obtenerDataLocal() {
    // this.dataUsuario = JSON.parse(localStorage.getItem("data_go504"));
    // if (!this.dataUsuario) {
    //   this.router.navigateByUrl('/login');
    // }
  }

}
