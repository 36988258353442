<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<button mat-icon-button (click)="authServices.CerrarSesion()" class="exit">
  <mat-icon style="color: #64748b; font-size: 24px !important; width: 24px; height: 24px;">exit_to_app</mat-icon> 
</button>

<!-- <button mat-icon-button [matMenuTriggerFor]="profile" class="m-r-5">
  <mat-icon style="color: #64748b; font-size: 24px !important; width: 24px; height: 24px;">account_circle</mat-icon>
</button>

<mat-menu #profile="matMenu" class="mymegamenu">
  
  <button mat-menu-item [routerLink]="['/perfil']">
    <mat-icon>account_circle</mat-icon> Perfil
  </button>
  
  <mat-divider></mat-divider>
  <button mat-menu-item (click)="authServices.CerrarSesion()" class="exit">
    <mat-icon>exit_to_app</mat-icon> Salir
  </button>
</mat-menu> -->